import {
    PRODUCT_ID_ENOCEAN_EMSIB,
    PRODUCT_ID_THINGSEE_AIR,
    PRODUCT_ID_BATTEN_STD,
    PRODUCT_ID_FUJITSU_FWM8BLZ08C,
    PRODUCT_ID_ENLIGHTING_MIN,
    PRODUCT_ID_ENLIGHTING_MAX
} from './constants'
import { state } from "@/store/main/state";

export function formatDate (dateString) {
    if(!dateString) return "";
    return formatDateRaw(dateString);
}

export function formatDateRaw (dateString) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Set this to true to display AM/PM
      timeZone: state.siteTimezone
    };
    return date.toLocaleDateString('en-US', options);
}

export function formatOnlyDate (dateString) {
    if(!dateString) return "";
    
    const date = new Date(dateString);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      timeZone: state.siteTimezone
    };
    return date.toLocaleDateString('en-US', options);
}

export function checkPassword(password) {
      const pattern = /^(?=.*[\d!@#$%^&*()-_=+[\]{};':"\\|,.<>?]).{8,}$/;
      return pattern.test(password);
}

export const GlobalFuncs = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        Vue.prototype.formatDate = formatDate;
    }
}

export function secondsToTimeStr(seconds) {
    let days = Math.floor(seconds / (60 * 60 * 24))
    seconds = seconds - days * (60 * 60 * 24)
    let hours = Math.floor(seconds / (60 * 60))
    seconds -= hours * 60 * 60
    let minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60
    return days + "D " + hours + "H " + minutes + "M " + seconds + "S"
}

/**
 * Checks if a product is an AirSensor by product ID
 * @param productId ID of product (String or Integer)
 * @returns {boolean} True if it is an AirSensor, False otherwise
 */
export function isProductAirSensor(productId) {

    let checkId = typeof(productId) === 'string' ? parseInt(productId) : productId
    return checkId === PRODUCT_ID_ENOCEAN_EMSIB || checkId === PRODUCT_ID_THINGSEE_AIR || checkId === PRODUCT_ID_FUJITSU_FWM8BLZ08C;

}

/**
 * Checks if a product is a non-emergency batten by product ID
 * @param productId ID of product
 * @returns {boolean} True if it is a non-emergency batten, False otherwise
 */
export function isNonEmergencyBatten(productId) {
    let id = typeof(productId) === 'string' ? parseInt(productId) : productId
    return id === PRODUCT_ID_BATTEN_STD;
}

/**
 * Checks if a product is a enLighten brand by product ID
 * @param productId ID of product
 * @returns {boolean} True if it is a enLighten, False otherwise
 */
export function isEnlightenProduct(productId) {
    let id = typeof(productId) === 'string' ? parseInt(productId) : productId
    return id >= PRODUCT_ID_ENLIGHTING_MIN && id <= PRODUCT_ID_ENLIGHTING_MAX;
}

export const EnlightenProductOnlineTime = (12 * 60 * 60);
export const SensorProductOnlineTime = (10 * 60);